@import 'global';
.advt {
  box-sizing: border-box;
  width: 320px;
  border-left: 1.5px solid rgb(211, 211, 211);
  border-top: 1.5px solid rgb(211, 211, 211);
  border-bottom: 1.5px solid rgb(211, 211, 211);
  padding: 12px;

  @include below($sm) {
    width: 0;
    display: none;
  }

  &_image {
    width: 300px;
  }

  &_text {
    color: #093454;
    line-height: 1.5;

    &--title {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
