@import './styles/global';
.app {
  font-family: 'Oxygen', sans-serif;

  &_body {
    box-sizing: border-box;
    padding: 10px 100px;
    width: 100%;
    display: flex;
    /*     justify-content: center;
    align-items: flex-start; */

    @include below($sm) {
      padding: 0;
    }
  }
}
