.footer {
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  background-color: #093454;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_text {
    color: white;
    text-align: center;

    &--email {
      font-size: 18px;
      color: rgb(255, 87, 21);
    }
  }
}
