@import 'global';

.info-bar {
  padding: 3px 0;
  width: 500px;
  margin: 10px 0;
  background-color: #542909;
  height: 100px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.48);
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  @include below($sm) {
    width: 100%;
    height: 70px;
  }

  &--small {
    width: 100%;
    height: 70px;
  }

  &_image-div {
    width: 100px;
    height: 100px;
    border-radius: 3px 0 0 3px;
    overflow: hidden;

    @include below($sm) {
      display: none;
    }
  }

  &_image {
    width: 100%;
    height: 100%;
    border-radius: 3px 0 0 3px;
    margin-right: 15px;
    object-fit: contain;
    object-position: center;
  }

  &_text-container {
    padding-left: 10px;
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &_text {
    color: white;

    &--name {
      margin: 0;
      font-size: 18px;
      font-weight: 800;
    }

    &--title {
      font-size: 12px;
      margin: 7px 0;
    }
  }

  &_icon {
    margin: 7px 7px 0 0;
  }
}
