@import 'global';

.contentlist {
  box-sizing: border-box;
  width: 320px;
  border-right: 1.5px solid rgb(211, 211, 211);
  border-top: 1.5px solid rgb(211, 211, 211);
  border-bottom: 1.5px solid rgb(211, 211, 211);
  padding: 12px;

  @include below($sm) {
    width: 0;
    display: none;
  }

  &_input {
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    border: 0.5px solid #09335498;
    border-radius: 3px;
    padding: 8px;
    color: #093454;
    margin: 15px 0;
  }

  &_list {
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px 0;
    border-top: 1px solid #09335498;
    border-bottom: 1px solid #09335498;
  }
  &_advt {
    margin-top: 15px;
  }
}
