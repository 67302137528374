@import 'global';

.header {
  max-width: 100%;
  margin-bottom: 20px;
  /*   border-bottom: 1px solid #093454;
 */
  @include below($sm) {
    padding: 15px;
  }

  &_items-container {
    max-width: 680px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include below($sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &_logo {
    height: 80px;
    width: auto;
    cursor: pointer;

    @include below($sm) {
      height: 40px;
      width: auto;
    }
  }

  &_tagline {
    color: #093454;
    margin-top: 50px;
    font-weight: 500;
    @include below($sm) {
      margin: 10px 0 0 0;
    }
  }
}
