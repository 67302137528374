@import 'global';
.info {
  box-sizing: border-box;
  width: 680px;
  border: 1.5px solid rgb(211, 211, 211);
  padding: 16px 22px;
  font-family: 'Oxygen', sans-serif;
  color: #093454;

  @include below($sm) {
    width: 100%;
    box-sizing: border-box;
    border: none;
  }

  &_name {
    font-size: 40px;
    font-weight: 800;
    margin: 0;
  }

  &_image {
    max-height: 300px;
    width: auto;
    margin: 30px 0;
  }
}

.markdown {
  font-family: 'Oxygen', sans-serif;

  line-height: 1.5;

  h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
  }
  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
}
