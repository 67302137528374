@import 'global';
.home {
  box-sizing: border-box;
  width: 680px;
  border: 1.5px solid rgb(211, 211, 211);
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include below($sm) {
    width: 100%;
    box-sizing: border-box;
    border: none;
  }

  &_titles {
    color: #093454;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }

  &_highlight {
    @include below($sm) {
      display: none;
    }
  }

  &_business {
    margin: 80px 0;
    @include below($sm) {
      display: none;
    }
  }

  &_search {
    max-width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    @include above($sm) {
      display: none;
    }
  }

  &_small-text {
    color: #093454;
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    margin: 0;
  }

  &_input {
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    border: 0.5px solid #09335498;
    border-radius: 3px;
    padding: 8px;
    color: #093454;
    margin: 15px 0;
  }

  &_body-text {
    color: #093454;
    font-family: 'Oxygen', sans-serif;

    &--center {
      text-align: center;
      font-weight: 600;
    }
  }

  &_top-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
